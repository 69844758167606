import type {
  FrameXinglet,
  FrameProps,
} from '@xing-com/crate-lebenslauf-xinglets';

import { Frame as FrameComponent } from './frame';

export default class Frame implements FrameXinglet {
  public getComponent() {
    return (props: FrameProps): JSX.Element => <FrameComponent {...props} />;
  }
}
