/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetVitaFieldsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetVitaFieldsQuery = {
  __typename?: 'Query';
  industries?: Array<{
    __typename?: 'Industry';
    id: string;
    label: string;
  }> | null;
  disciplines?: Array<{
    __typename?: 'Discipline';
    id: string;
    label: string;
  }> | null;
  careerLevels?: Array<{
    __typename?: 'CareerLevel';
    id: string;
    label: string;
  }> | null;
};

export const GetVitaFieldsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetVitaFields' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'industries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'VitaIndustryField' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disciplines' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'VitaDisciplineField' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'careerLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'VitaCareerLevelField' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VitaIndustryField' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Industry' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'label' },
            name: { kind: 'Name', value: 'localizationValue' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VitaDisciplineField' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Discipline' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'label' },
            name: { kind: 'Name', value: 'localizationValue' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VitaCareerLevelField' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CareerLevel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'label' },
            name: { kind: 'Name', value: 'localizationValue' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetVitaFieldsQuery, GetVitaFieldsQueryVariables>;
