import { useQuery } from '@apollo/client';

import { defaultProfessionalFields } from '@xing-com/crate-lebenslauf-constants';
import {
  type VitaFieldsData,
  GetVitaFieldsDocument,
} from '@xing-com/crate-lebenslauf-graphql';

export const useVitaFields = (): VitaFieldsData => {
  const { data } = useQuery(GetVitaFieldsDocument);

  const industries = data?.industries || defaultProfessionalFields.industries;

  const careerLevels =
    data?.careerLevels || defaultProfessionalFields.careerLevels;

  const disciplines =
    data?.disciplines || defaultProfessionalFields.disciplines;

  return { industries, careerLevels, disciplines };
};
