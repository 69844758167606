import styled from 'styled-components';

import { Button } from '@xing-com/crate-lebenslauf-button';
import { mediaWideNavless } from '@xing-com/layout-tokens';
import { Menu } from '@xing-com/menu';
import { SkeletonButton } from '@xing-com/skeleton';
import {
  spaceM,
  spaceL,
  spaceXL,
  space4XL,
  xdlColorText,
  scale130,
} from '@xing-com/tokens';

export const StyledLoginButton = styled(Button)`
  margin-top: ${spaceL};
  flex-shrink: 0;

  @media ${mediaWideNavless} {
    margin-top: 0;
    margin-left: ${spaceXL};
  }
`;

export const StyledProfileMenu = styled.button`
  flex-shrink: 0;
  padding: 0;
  border: 0 none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  margin-top: ${spaceL};

  @media ${mediaWideNavless} {
    margin-top: 0;
    margin-left: ${spaceXL} !important;
  }
`;

export const StyledMenu = styled(Menu)<{ $isOnProgressBar?: boolean }>`
  position: fixed;
  top: 70px;
  right: ${({ $isOnProgressBar }) =>
    $isOnProgressBar ? 'calc((50vw - var(--previewWidth)) / 2)' : space4XL};
`;

export const StyledLink = styled.a`
  display: block;
  padding: ${spaceM} 0;
  color: ${xdlColorText};
`;

export const StyledSkeletonButton = styled(SkeletonButton)`
  margin-left: ${spaceXL};
  flex-shrink: 0;
  width: ${scale130};
`;
