import styled from 'styled-components';

import { mediaWideNavless } from '@xing-com/layout-tokens';
import {
  space4XL,
  spaceL,
  spaceXL,
  spaceXXL,
  xdlColorBackground,
  xdlColorBorderSoft,
  xdlColorText,
  xdlColorTextSecondary,
  xdlColorBackgroundSecondary,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const StyledFooter = styled.footer`
  position: relative;
  background-color: ${xdlColorBackground};
  border-top: 1px solid ${xdlColorBorderSoft};
  z-index: 1;
  padding: ${spaceL};

  @media ${mediaWideNavless} {
    padding: ${space4XL};
  }
`;

export const StyledSection = styled.section`
  display: flex;
  flex-direction: column;

  @media ${mediaWideNavless} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const StyledLinkGroupDesktop = styled.nav`
  display: none;

  @media ${mediaWideNavless} {
    display: block;
    margin-bottom: ${space4XL};
  }
`;

export const StyledLinkGroupMobile = styled.details`
  &:last-child {
    margin-bottom: ${spaceXL};
  }

  & summary svg:last-child,
  &[open] summary svg {
    display: none;
  }

  &[open] summary svg:last-child {
    display: block;
  }

  @media ${mediaWideNavless} {
    display: none;
  }
`;

export const StyledSummary = styled.summary`
  background-color: ${xdlColorBackground};
  font-weight: bold;
  padding-bottom: ${spaceL};
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ::-webkit-details-marker {
    display: none;
  }
`;

export const StyledLink = styled.a`
  display: block;
  color: ${xdlColorText};
  padding: ${spaceL} ${spaceXXL};
  background-color: ${xdlColorBackgroundSecondary};
  margin: 0 -${spaceL};

  @media ${mediaWideNavless} {
    padding: 0;
    margin: ${spaceL} 0 0 0;
    background-color: transparent;
  }
`;

export const StyledLegalWrapper = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;

  @media ${mediaWideNavless} {
    align-items: center;
    flex-direction: row;
  }
`;

export const StyledLegalCopy = styled(BodyCopy)`
  color: ${xdlColorTextSecondary};

  @media ${mediaWideNavless} {
    margin: 0 ${spaceXXL} 0 ${spaceXXL};
  }
`;

export const StyledLegalLink = styled.a`
  display: inline-block;
  color: ${xdlColorTextSecondary};
  margin-top: ${spaceL};

  @media ${mediaWideNavless} {
    margin-right: ${spaceXXL};
    margin-top: 0;

    &:last-child {
      margin-right: 0;
    }
  }
`;
