type Section =
  | 'personal-information'
  | 'professional-information'
  | 'educational-information'
  | 'skills-information'
  | 'summary-information';

export type SerializeName = (
  section: Section,
  name: string,
  index?: number,
  subIndex?: number
) => string;

export type DeserializeName = (fieldName: string) => {
  section: string;
  name: string;
  index?: number;
  subIndex?: number;
};

export const useNameSerialization = (): {
  serializeName: SerializeName;
  deserializeName: DeserializeName;
} => {
  const serializeName: SerializeName = (section, name, index, subIndex) => {
    if (typeof index === 'number') {
      if (typeof subIndex === 'number') {
        return `${section}_${name}_${index}_${subIndex}`;
      }
      return `${section}_${name}_${index}`;
    }

    return `${section}_${name}`;
  };

  const deserializeName: DeserializeName = (fieldName) => {
    const [section, name, index, subIndex] = fieldName.split('_');
    return {
      section,
      name,
      index: parseInt(index),
      subIndex: parseInt(subIndex),
    };
  };
  return { serializeName, deserializeName };
};
