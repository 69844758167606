// @xing-com/hub isDarkMode is not reliable on logged out for now. until is fixed we can use this workaround
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { useFrameHooks } from '@xing-com/hub';

export const useIsDarkMode = (): boolean => {
  const { useIsDarkMode } = useFrameHooks();
  const isDarkMode = useIsDarkMode();
  const { isLoggedOut } = useLoginState();

  if (isLoggedOut) return false;
  return isDarkMode;
};
