import { useLocation } from '@reach/router';

import { useHost } from '@xing-com/crate-xinglet';

import { useIsCrateApp } from './use-is-crate-app';

type VitaHost = {
  isPreview: boolean;
  loginUrl: string;
  logoutUrl: string;
};

export const useVitaHost = (): VitaHost => {
  const { isPreview: isCratePreview, getHostname } = useHost();
  const { pathname } = useLocation();
  const isCrateApp = useIsCrateApp();
  const hostname = getHostname();
  const isPreview =
    (isCratePreview && isCrateApp) ||
    hostname === 'vita.preview.lebenslauf.com' ||
    hostname === 'preview.anschreiben.com';

  const host = hostname.replace(/^www\./, '');
  const destUrl = encodeURIComponent(
    isCrateApp ? pathname : `https://${host}${pathname}`
  );

  const isLocalHost = hostname.includes('localhost');
  const localUrl = `http://localhost:8080/_fakeauth`;
  const loginUrl = isLocalHost
    ? localUrl
    : `https://login.${isPreview ? 'preview.' : ''}xing.com/?dest_url=${destUrl}&signup_channel=lebenslaufvita`;
  const logoutUrl = isLocalHost ? localUrl : `https://${host}/login/logout`;

  return { isPreview, loginUrl, logoutUrl };
};
