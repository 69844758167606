import { Link } from '@reach/router';
import styled, { css, createGlobalStyle } from 'styled-components';

import { IconButton } from '@xing-com/button';
import { mainContentWrapperStyles } from '@xing-com/crate-lebenslauf-components';
import { IconNewChat, IconNews, IconTopicChannels } from '@xing-com/icons';
import { mediaWideNavless } from '@xing-com/layout-tokens';
import {
  space4XL,
  space5XL,
  spaceL,
  spaceS,
  spaceXL,
  spaceXS,
  xdlColorBackground,
  xdlColorBorderSoft,
  xdlColorText,
} from '@xing-com/tokens';

export const GlobalStyles = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }
`;

export const StyledHeader = styled.header`
  background-color: ${xdlColorBackground};
  border-bottom: 1px solid ${xdlColorBorderSoft};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
`;

export const StyledCollapsedNavigation = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  height: 54px;

  @media ${mediaWideNavless} {
    padding: 0 ${spaceL};
    height: 88px;
  }

  @media ${mediaWideNavless} {
    padding: 0 ${space4XL};
  }
`;

export const StyledExpandedNavigation = styled.nav`
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: ${xdlColorBackground};
  top: 0;
  right: 0;
`;

export const StyledLogoLink = styled(Link)`
  height: 33px;
  display: block;

  @media ${mediaWideNavless} {
    height: 42px;
  }
`;

export const StyledLogo = styled.img`
  height: 33px;
  margin-left: ${spaceL};

  @media ${mediaWideNavless} {
    height: 42px;
    margin-left: 0;
  }
`;

export const StyledMenuWrapper = styled.div<{
  $expanded: boolean;
  $hideOnDesktop: boolean;
}>`
  ${(props) => (props.$expanded ? 'display: flex' : 'display: none')};
  flex-direction: column;
  border-top: 1px solid ${xdlColorBorderSoft};
  padding: ${spaceL};
  align-items: flex-start;
  white-space: nowrap;

  @media ${mediaWideNavless} {
    display: ${(props) => (props.$hideOnDesktop ? 'none' : 'flex')};
    align-items: center;
    flex-direction: row;
    border-top: 0 none;
    padding: 0;
  }
`;

export const StyledLink = styled(Link)<{
  $expanded: boolean;
  $opened?: boolean;
}>`
  ${(props) => props.$expanded && `margin-bottom: ${spaceL};`};
  ${(props) => props.$opened && `font-weight: bold;`};
  margin-right: ${spaceXL};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${xdlColorText};

  @media ${mediaWideNavless} {
    flex-direction: column;
  }
`;

const iconStyles = css`
  margin-right: ${spaceS};

  @media ${mediaWideNavless} {
    margin-right: 0;
  }
`;

export const StyledIconNewChat = styled(IconNewChat)`
  ${iconStyles}
`;
export const StyledIconNews = styled(IconNews)`
  ${iconStyles}
`;
export const StyledIconTopicChannels = styled(IconTopicChannels)`
  ${iconStyles}
`;

export const StyledIconButton = styled(IconButton)`
  @media ${mediaWideNavless} {
    display: none;
  }
  ${(props) =>
    // @ts-expect-error FIXME: SC6
    props.$expanded &&
    css`
      position: absolute;
      right: 0;
      top: ${spaceXS};
    `}
`;

export const StyledMainContent = styled.main<{
  $noMainContentWrapperStyles: boolean;
  $hasBanner: boolean;
}>`
  ${({ $noMainContentWrapperStyles, $hasBanner }) => {
    if (!$noMainContentWrapperStyles) {
      return css`
        ${mainContentWrapperStyles}
      `;
    }
    return css`
      margin-bottom: ${$hasBanner ? space5XL : 0};
    `;
  }}
`;
