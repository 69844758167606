import { FormattedMessage, useIntl } from 'react-intl';

import { useVisitorId } from '@xing-com/hub';
import { IconArrowDown, IconArrowUp } from '@xing-com/icons';
import { Headline } from '@xing-com/typography';

import {
  StyledFooter,
  StyledLinkGroupDesktop,
  StyledLinkGroupMobile,
  StyledSummary,
  StyledLink,
  StyledSection,
  StyledLegalCopy,
  StyledLegalWrapper,
  StyledLegalLink,
} from './footer.styled';

const LinkGroup: React.FC<
  React.PropsWithChildren<{ title: string; open?: boolean }>
> = ({ title, children, open }) => (
  <>
    <StyledLinkGroupDesktop>
      <Headline size="small" noMargin>
        {title}
      </Headline>
      {children}
    </StyledLinkGroupDesktop>
    <StyledLinkGroupMobile open={open}>
      <StyledSummary>
        {title}
        <IconArrowDown width="24" height="24" />
        <IconArrowUp width="24" height="24" />
      </StyledSummary>
      {children}
    </StyledLinkGroupMobile>
  </>
);

export const Footer: React.FC = () => {
  const intl = useIntl();

  const visitorId = useVisitorId();
  const scCdvidValue = encodeURIComponent(btoa(visitorId));

  return (
    <StyledFooter>
      <StyledSection>
        <LinkGroup
          title={intl.formatMessage({ id: 'CRATE_VITA_FOOTER_COMPANY' })}
        >
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href="https://www.new-work.se/en?sc_o=navigation_footer"
          >
            <FormattedMessage id="CRATE_VITA_FOOTER_NEWWORK" />
          </StyledLink>
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href="https://www.new-work.se/en/career/?sc_o=navigation_footer"
          >
            <FormattedMessage id="CRATE_VITA_FOOTER_CAREER" />
          </StyledLink>
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href="https://www.new-work.se/en/investor-relations/?sc_o=navigation_footer"
          >
            <FormattedMessage id="CRATE_VITA_FOOTER_INVESTOR_RELATIONS" />
          </StyledLink>
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href="https://www.new-work.se/en/newsroom/?sc_o=navigation_footer"
          >
            <FormattedMessage id="CRATE_VITA_FOOTER_PRESS" />
          </StyledLink>
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href="https://nwx.new-work.se/?sc_o=navigation_footer"
          >
            <FormattedMessage id="CRATE_VITA_FOOTER_NWX" />
          </StyledLink>
        </LinkGroup>

        <LinkGroup
          title={intl.formatMessage({ id: 'CRATE_VITA_FOOTER_RESOURCES' })}
        >
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href="https://faq.xing.com/de?sc_o=navigation_footer"
          >
            <FormattedMessage id="CRATE_VITA_FOOTER_HELP" />
          </StyledLink>
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href="https://tech.new-work.se/?sc_o=navigation_footer"
          >
            <FormattedMessage id="CRATE_VITA_FOOTER_DEVBLOG" />
          </StyledLink>
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href="https://mobile.xing.com/de/?sc_o=navigation_footer"
          >
            <FormattedMessage id="CRATE_VITA_FOOTER_APPS" />
          </StyledLink>
        </LinkGroup>

        <LinkGroup
          title={intl.formatMessage({ id: 'CRATE_VITA_FOOTER_MEMBERSHIPS' })}
        >
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href="https://www.xing.com/purchase/premium?reagent=uplt_98&sc_o=navigation_footer"
          >
            <FormattedMessage id="CRATE_VITA_FOOTER_PREMIUM" />
          </StyledLink>
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href="https://www.xing.com/purchase/projobs?reagent=uplt_205&sc_o=navigation_footer"
          >
            <FormattedMessage id="CRATE_VITA_FOOTER_PROJOBS" />
          </StyledLink>
        </LinkGroup>

        <LinkGroup title={intl.formatMessage({ id: 'CRATE_VITA_FOOTER_MAIN' })}>
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href="https://www.xing.com/people/?sc_o=navigation_footer"
          >
            <FormattedMessage id="CRATE_VITA_FOOTER_USERS" />
          </StyledLink>
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href="https://www.xing.com/jobs?sc_o=navigation_footer"
          >
            <FormattedMessage id="CRATE_VITA_FOOTER_JOBS" />
          </StyledLink>
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href="https://www.xing.com/news?sc_o=navigation_footer"
          >
            <FormattedMessage id="CRATE_VITA_FOOTER_NEWS" />
          </StyledLink>
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href="https://www.xing.com/companies?sc_o=navigation_footer"
          >
            <FormattedMessage id="CRATE_VITA_FOOTER_COMPANIES" />
          </StyledLink>
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href="https://coaches.xing.com/?sc_o=navigation_footer"
          >
            <FormattedMessage id="CRATE_VITA_FOOTER_COACHES" />
          </StyledLink>
        </LinkGroup>

        <LinkGroup
          title={intl.formatMessage({ id: 'CRATE_VITA_FOOTER_SERVICES' })}
        >
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href="https://recruiting.xing.com/de/?sc_o=navigation_footer"
          >
            <FormattedMessage id="CRATE_VITA_FOOTER_EMPLOYEES" />
          </StyledLink>
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href="https://www.hallofreelancer.com/?sc_o=navigation_footer"
          >
            <FormattedMessage id="CRATE_VITA_FOOTER_FREELANCER" />
          </StyledLink>
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href="https://www.xing.com/xam/personal/ads?sc_o=navigation_footer"
          >
            <FormattedMessage id="CRATE_VITA_FOOTER_ADS" />
          </StyledLink>
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href="https://werben.xing.com/?sc_o=navigation_footer"
          >
            <FormattedMessage id="CRATE_VITA_FOOTER_ADVERTISEMENTS" />
          </StyledLink>
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href="https://lebenslauf.com/?sc_o=navigation_footer"
          >
            <FormattedMessage id="CRATE_VITA_FOOTER_LEBENSLAUF" />
          </StyledLink>
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href="https://anschreiben.com/?sc_o=navigation_footer"
          >
            <FormattedMessage id="CRATE_VITA_FOOTER_ANSCHREIBEN" />
          </StyledLink>
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href="https://bewerbung.com/?sc_o=navigation_footer"
          >
            <FormattedMessage id="CRATE_VITA_FOOTER_ADVISOR" />
          </StyledLink>
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href={`https://www.kununu.com/?sc_o=navigation_footer&sc_cdvid=${scCdvidValue}`}
          >
            <FormattedMessage id="CRATE_VITA_FOOTER_RATINGS" />
          </StyledLink>
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href="https://praktikum-guide.com/?sc_o=navigation_footer"
          >
            <FormattedMessage id="CRATE_VITA_FOOTER_INTERNSHIPS" />
          </StyledLink>
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href="https://www.xing.com/karriere-ratgeber?sc_o=navigation_footer"
          >
            <FormattedMessage id="CRATE_VITA_FOOTER_CAREER_ADVISORY" />
          </StyledLink>
        </LinkGroup>
      </StyledSection>

      <StyledSection>
        <StyledLegalWrapper>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://xing.com"
            aria-label="XING Startseite"
          >
            <svg
              viewBox="0 0 106 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              data-xds="LogoXINGLogoDuo"
              height="29"
              width="64"
            >
              <g
                clipPath="url(#prefix__clip0_47_1328)"
                fillRule="evenodd"
                clipRule="evenodd"
              >
                <path
                  d="M1.805 5.984l3.635 6.479L0 22.018h6.491l5.4-9.555-3.634-6.48H1.805z"
                  fill="#0698A0"
                ></path>
                <path
                  d="M21.122 0l-10.49 18.642L17.396 31h6.582l-6.88-12.358L27.6 0h-6.478z"
                  fill="#B7DF4B"
                ></path>
                <path
                  d="M40.505 2.998l3.777 7.979 4.129-7.979h5.387l-6.608 11.98 6.868 13.024h-5.647l-4.245-8.735-4.207 8.735h-5.426l6.543-12.958-6.258-12.046h5.687zm16.578 25.004h5.245V2.998h-5.245v25.004zm48.904-14.353h-8.672v4.315h3.869V23.4a6.556 6.556 0 01-2.61.508c-3.972 0-5.621-2.985-5.621-8.304 0-4.966 1.428-8.564 6.127-8.564 1.571 0 3.298.43 4.986 1.186l1.506-3.99c-2.078-1.081-4.336-1.55-6.7-1.55-7.698 0-11.1 4.862-11.1 12.88 0 8.199 3.26 12.775 10.815 12.775 2.441 0 4.907-.509 7.413-1.695V13.649h-.013zM71.26 2.999h-4.414v25.003h5.102V13.857l8.412 14.145h4.271V2.998H79.53v14.145L71.26 2.998z"
                  fill="#0698A0"
                ></path>
              </g>
              <defs>
                <clipPath id="prefix__clip0_47_1328">
                  <path fill="#fff" d="M0 0h106v31H0z"></path>
                </clipPath>
              </defs>
            </svg>
          </a>
          <StyledLegalCopy size="small">
            <FormattedMessage id="CRATE_VITA_FOOTER_COPYRIGHTS" />
          </StyledLegalCopy>
          <StyledLegalLink
            target="_blank"
            rel="noreferrer"
            href="https://www.xing.com/legalnotice?sc_o=navigation_footer"
          >
            <FormattedMessage id="CRATE_VITA_FOOTER_LEGAL" />
          </StyledLegalLink>
          <StyledLegalLink
            target="_blank"
            rel="noreferrer"
            href="https://www.xing.com/terms?sc_o=navigation_footer"
          >
            <FormattedMessage id="CRATE_VITA_FOOTER_TERMS" />
          </StyledLegalLink>
          <StyledLegalLink
            target="_blank"
            rel="noreferrer"
            href="https://privacy.xing.com/de?sc_o=navigation_footer"
          >
            <FormattedMessage id="CRATE_VITA_FOOTER_PRIVACY" />
          </StyledLegalLink>
          <StyledLegalLink
            target="_blank"
            rel="noreferrer"
            href="https://privacy.xing.com/de/datenschutzerklaerung?sc_o=navigation_footer"
          >
            <FormattedMessage id="CRATE_VITA_FOOTER_DATA" />
          </StyledLegalLink>
          <StyledLegalLink
            target="_blank"
            rel="noreferrer"
            href="https://www.xing.com/support/contact/memberships?sc_o=navigation_footer"
          >
            <FormattedMessage id="CRATE_VITA_FOOTER_CANCEL" />
          </StyledLegalLink>
        </StyledLegalWrapper>
      </StyledSection>
    </StyledFooter>
  );
};
