import type {
  VitaEducationExperienceFragment,
  VitaProfessionalExperienceFragment,
} from '@xing-com/crate-lebenslauf-graphql';

export const PROFESSIONAL_INFORMATION_DEFAULTS: VitaProfessionalExperienceFragment =
  {
    company: '',
    startDate: '',
    endDate: '',
    occupation: '',
    descriptions: [],
    industry: { id: '', value: '' },
    discipline: { id: '', value: '' },
    isCurrentEmployer: false,
    jobLevel: { id: '', value: '' },
  };

export const EDUCATIONAL_INFORMATION_DEFAULTS: VitaEducationExperienceFragment =
  {
    institution: '',
    startDate: '',
    endDate: '',
    courseOfStudy: '',
    degree: '',
    isStillStudy: false,
  };
