export const PAGE_WIDTH = 596;
export const PAGE_HEIGHT = 840;
export { COLORS } from './colors';
export { templates } from './templates';
export type { Template } from './templates';

import type { VitaFieldsData } from '@xing-com/crate-lebenslauf-graphql';

import { careerLevels } from './career-levels';
import { disciplines } from './disciplines';
import { industries } from './industries';

export const defaultProfessionalFields: VitaFieldsData = {
  careerLevels,
  industries,
  disciplines,
};
