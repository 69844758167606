import type { VitaDisciplineFieldFragment } from '@xing-com/crate-lebenslauf-graphql';

export const disciplines: VitaDisciplineFieldFragment[] = [
  { id: '1002', label: 'Administration, Sachbearbeitung und Verwaltung' },
  { id: '1001', label: 'Analyse und Statistik' },
  { id: '1003', label: 'Beratung / Consulting' },
  { id: '1005', label: 'Einkauf, Materialwirtschaft und Logistik' },
  { id: '1006', label: 'Finanzen, Rechnungswesen und Controlling' },
  { id: '1007', label: 'Forschung, Lehre und Entwicklung' },
  { id: '1008', label: 'Gesundheit, Medizin und Soziales' },
  { id: '1009', label: 'Grafik, Design und Architektur' },
  { id: '1011', label: 'IT und Softwareentwicklung' },
  { id: '1010', label: 'Ingenieurwesen und technische Berufe' },
  { id: '1004', label: 'Kundenbetreuung' },
  { id: '1012', label: 'Management und Unternehmensentwicklung' },
  { id: '1013', label: 'Marketing und Werbung' },
  { id: '1015', label: 'PR, Öffentlichkeitsarbeit und Journalismus' },
  { id: '1014', label: 'Personalwesen und HR' },
  { id: '1016', label: 'Produktion und Handwerk' },
  { id: '1017', label: 'Produktmanagement' },
  { id: '1018', label: 'Projektmanagement' },
  { id: '1019', label: 'Prozessplanung und Qualitätssicherung' },
  { id: '1020', label: 'Recht' },
  { id: '1022', label: 'Sonstige Tätigkeitsfelder' },
  { id: '1021', label: 'Vertrieb und Handel' },
];
