import type {
  VitaDocumentCvFragment,
  VitaDocumentFileAttachmentsFragment,
  VitaDocumentFragment,
  VitaPreferencesFragment,
} from '@xing-com/crate-lebenslauf-graphql';
import {
  sharedClientDocumentVariable,
  vitaClientDocumentInitialValues,
  type VitaDocumentType,
} from '@xing-com/crate-lebenslauf-shared-client-document-variable';

import {
  EDUCATIONAL_INFORMATION_DEFAULTS,
  PROFESSIONAL_INFORMATION_DEFAULTS,
} from './const';
import type {
  AddProfessionalInformationFields,
  EditPersonalInformation,
  EditProfessionalInformationFields,
  DeleteProfessionalInformationFields,
  AddProfessionalDescriptionField,
  DeleteProfessionalDescriptionField,
  AddEducationalInformationFields,
  EditEducationalInformationFields,
  DeleteEducationalInformationFields,
  AddSkill,
  DeleteSkill,
  FileAttachmentActions,
  ThemeActions,
  ValidationActions,
} from './types';
import { useNameSerialization } from './use-name-serialization';
import { useVitaFields } from './use-vita-fields';

type UpdateCV = {
  type: 'update-cv';
  value?: VitaDocumentCvFragment | null;
};

type EditAboutMeInformationFields = {
  type: 'edit-about-me-field';
  value: string;
};

type EditSuggestionTrackingToken = {
  type: 'edit-suggestion-tracking-token';
  value: string;
};

export type CvArgs =
  | UpdateCV
  | EditPersonalInformation
  | EditProfessionalInformationFields
  | AddProfessionalInformationFields
  | AddProfessionalDescriptionField
  | DeleteProfessionalDescriptionField
  | DeleteProfessionalInformationFields
  | AddEducationalInformationFields
  | EditEducationalInformationFields
  | DeleteEducationalInformationFields
  | AddSkill
  | AddPreferences
  | DeleteSkill
  | EditAboutMeInformationFields
  | EditSuggestionTrackingToken;

type CvActions = (args: CvArgs) => void;

type AddPreferences = {
  type: 'add-preferences';
  value: VitaPreferencesFragment;
};

type UseClientDocumentActions = () => {
  themeActions: ThemeActions;
  cvActions: CvActions;
  documentActions: DocumentActions;
  validationActions: ValidationActions;
  fileAttachmentActions: FileAttachmentActions;
  toggleShowAnschreibenBanner: () => void;
  deleteLoggedInData: () => void;
};

export type DocumentArgs =
  | EditDocument
  | EditDocumentToken
  | EditDocumentId
  | EditProfileImageId;

type EditDocument = {
  type: 'edit-document';
  value: VitaDocumentFragment;
};

type EditDocumentToken = {
  type: 'edit-document-token';
  value?: string | null;
};

type EditDocumentId = {
  type: 'edit-document-id';
  value?: string | null;
};

type EditProfileImageId = {
  type: 'edit-profile-image-id';
  value?: string | null;
};

export type DocumentActions = (args: DocumentArgs) => void;

const sharedClientDocumentVariableWithLocalStorage = (
  doc: VitaDocumentType
): void => {
  sharedClientDocumentVariable(doc);
  global?.window?.localStorage &&
    global.window.localStorage.setItem(
      'vita-document',
      JSON.stringify({ ...doc, token: '' })
    );
};

export const useVitaReactiveVariableActions: UseClientDocumentActions = () => {
  const { deserializeName } = useNameSerialization();
  const { industries, disciplines, careerLevels } = useVitaFields();
  const themeActions: ThemeActions = ({ type, value }) => {
    const doc = sharedClientDocumentVariable();
    switch (type) {
      case 'change-color': {
        sharedClientDocumentVariableWithLocalStorage({
          ...doc,
          theme: { ...doc.theme, color: value },
        });
        break;
      }

      case 'change-template': {
        sharedClientDocumentVariableWithLocalStorage({
          ...doc,
          theme: { ...doc.theme, template: value },
        });
        break;
      }

      default:
        break;
    }
  };

  const cvActions: CvActions = ({ type, value }) => {
    const doc = sharedClientDocumentVariable();
    switch (type) {
      case 'update-cv': {
        sharedClientDocumentVariableWithLocalStorage({
          ...doc,
          cv: value,
        });
        break;
      }

      case 'edit-personal-information-fields': {
        const { name } = deserializeName(value.name);

        sharedClientDocumentVariableWithLocalStorage({
          ...doc,
          cv: {
            ...doc.cv,
            personalInformation: {
              ...doc.cv?.personalInformation,
              [name]: value.value,
            },
          },
        });

        break;
      }

      case 'add-professional-information-fields': {
        const professionalExperience = [
          ...(doc.cv?.professionalExperience ?? []),
        ];
        professionalExperience.push(PROFESSIONAL_INFORMATION_DEFAULTS);
        sharedClientDocumentVariableWithLocalStorage({
          ...doc,
          cv: {
            ...doc.cv,
            professionalExperience: [...professionalExperience],
          },
        });
        break;
      }

      case 'add-professional-description-field': {
        const professionalExperienceDoc = doc.cv?.professionalExperience ?? [];
        const professionalExperience = [...professionalExperienceDoc].map(
          (data, index) => {
            const descriptions = data?.descriptions ?? [];
            if (index === value) {
              return {
                ...data,
                descriptions: [...descriptions, { description: '' }],
              };
            }
            return data;
          }
        );

        sharedClientDocumentVariableWithLocalStorage({
          ...doc,
          cv: {
            ...doc.cv,
            professionalExperience: [...professionalExperience],
          },
        });
        break;
      }

      case 'delete-professional-description-field': {
        const { index, subIndex } = value;
        const professionalExperienceDoc = doc.cv?.professionalExperience ?? [];
        const descriptions =
          professionalExperienceDoc[index]?.descriptions ?? [];

        const professionalExperience = doc.cv?.professionalExperience?.map(
          (data, arrayIndex) => {
            if (arrayIndex === index) {
              return {
                ...data,
                descriptions: [
                  ...descriptions.filter(
                    (_, filterIndex) => filterIndex !== subIndex
                  ),
                ],
              };
            }
            return data;
          }
        );
        sharedClientDocumentVariableWithLocalStorage({
          ...doc,
          cv: { ...doc.cv, professionalExperience },
        });
        break;
      }

      case 'delete-professional-information-fields': {
        const professionalExperience = [
          ...(doc.cv?.professionalExperience ?? []),
        ].filter((_, filterIndex) => filterIndex !== value);

        sharedClientDocumentVariableWithLocalStorage({
          ...doc,
          cv: {
            ...doc.cv,
            professionalExperience: [...professionalExperience],
          },
        });
        break;
      }

      case 'edit-professional-information-fields': {
        const { name, index, subIndex } = deserializeName(value.name);

        const professionalExperience = [
          ...(doc.cv?.professionalExperience ?? []),
        ].map((data, arrayIndex) => {
          if (arrayIndex === index) {
            if (name === 'description' && subIndex !== undefined) {
              const descriptions = [...(data?.descriptions ?? [])];
              descriptions[subIndex] = {
                description: value.value?.toString(),
              };
              return {
                ...data,
                descriptions: [...descriptions],
              };
            }
            if (name === 'jobLevel') {
              const jobLevelId = `${value.value}`;
              const jobLevelValue = careerLevels.find(
                (level) => level.id === jobLevelId
              )?.label;

              return {
                ...data,
                jobLevel: {
                  ...data?.jobLevel,
                  id: jobLevelId,
                  value: jobLevelValue,
                },
              };
            }
            if (name === 'discipline') {
              const disciplineId = `${value.value}`;
              const disciplineValue = disciplines.find(
                (level) => level.id === disciplineId
              )?.label;

              return {
                ...data,
                discipline: {
                  ...data?.discipline,
                  id: disciplineId,
                  value: disciplineValue,
                },
              };
            }

            if (name === 'industry') {
              const industryId = `${value.value}`;
              const industryValue = industries.find(
                (level) => level.id === industryId
              )?.label;

              return {
                ...data,
                industry: {
                  ...data?.industry,
                  id: industryId,
                  value: industryValue,
                },
              };
            }
            return { ...data, [name]: value.value };
          }
          return data;
        });

        sharedClientDocumentVariableWithLocalStorage({
          ...doc,
          cv: {
            ...doc.cv,
            professionalExperience: [...professionalExperience],
          },
        });
        break;
      }

      case 'add-educational-experience-fields': {
        const educationExperience = [...(doc.cv?.educationExperience ?? [])];
        educationExperience.push(EDUCATIONAL_INFORMATION_DEFAULTS);
        sharedClientDocumentVariableWithLocalStorage({
          ...doc,
          cv: {
            ...doc.cv,
            educationExperience: [...educationExperience],
          },
        });
        break;
      }

      case 'edit-educational-experience-fields': {
        const { name, index } = deserializeName(value.name);
        const educationExperience = [
          ...(doc.cv?.educationExperience ?? []),
        ].map((data, arrayIndex) => {
          if (arrayIndex === index) return { ...data, [name]: value.value };
          return data;
        });

        sharedClientDocumentVariableWithLocalStorage({
          ...doc,
          cv: {
            ...doc.cv,
            educationExperience: [...educationExperience],
          },
        });
        break;
      }

      case 'delete-educational-experience-fields': {
        const educationExperience = [
          ...(doc.cv?.educationExperience ?? []),
        ].filter((_, filterIndex) => filterIndex !== value);

        sharedClientDocumentVariableWithLocalStorage({
          ...doc,
          cv: {
            ...doc.cv,
            educationExperience: [...educationExperience],
          },
        });
        break;
      }

      case 'add-skill': {
        const skillsSet = new Set(doc.cv?.skills?.map((item) => item?.skill));
        skillsSet.add(value);
        const skills = Array.from(skillsSet).map((skill) => ({ skill }));
        sharedClientDocumentVariableWithLocalStorage({
          ...doc,
          cv: { ...doc.cv, skills },
        });
        break;
      }

      case 'delete-skill': {
        const skills = [...(doc.cv?.skills ?? [])].filter(
          (_, filterIndex) => filterIndex !== value
        );
        sharedClientDocumentVariableWithLocalStorage({
          ...doc,
          cv: { ...doc.cv, skills },
        });
        break;
      }

      case 'edit-about-me-field': {
        sharedClientDocumentVariableWithLocalStorage({
          ...doc,
          cv: {
            ...doc.cv,
            aboutMe: value,
          },
        });

        break;
      }

      case 'edit-suggestion-tracking-token': {
        sharedClientDocumentVariableWithLocalStorage({
          ...doc,
          suggestionTrackingToken: value,
        });
        break;
      }

      case 'add-preferences': {
        sharedClientDocumentVariableWithLocalStorage({
          ...doc,
          cv: {
            ...doc.cv,
            preferences: {
              ...doc.cv?.preferences,
              ...value,
            },
          },
        });

        break;
      }

      default:
        break;
    }
  };

  const documentActions: DocumentActions = ({ type, value }) => {
    const doc = sharedClientDocumentVariable();
    switch (type) {
      case 'edit-document':
        {
          sharedClientDocumentVariableWithLocalStorage({
            ...doc,
            ...value,
          });
        }
        break;

      case 'edit-document-token': {
        sharedClientDocumentVariableWithLocalStorage({ ...doc, token: value });
        break;
      }

      case 'edit-document-id': {
        sharedClientDocumentVariableWithLocalStorage({ ...doc, id: value });
        break;
      }

      case 'edit-profile-image-id': {
        sharedClientDocumentVariableWithLocalStorage({
          ...doc,
          fileAttachments: {
            ...doc.fileAttachments,
            profileImage: { ...doc.fileAttachments?.profileImage, id: value },
          },
        });
        break;
      }
    }
  };

  const validationActions: ValidationActions = ({ type, value }) => {
    const doc = sharedClientDocumentVariable();
    switch (type) {
      case 'add-invalid-form-input-name': {
        const invalidFormInputNames = [
          ...doc.validation.invalidFormInputNames,
          value,
        ];
        sharedClientDocumentVariableWithLocalStorage({
          ...doc,
          validation: {
            ...doc.validation,
            invalidFormInputNames,
          },
        });
        break;
      }

      case 'add-all-invalid-form-input-names': {
        sharedClientDocumentVariableWithLocalStorage({
          ...doc,
          validation: {
            ...doc.validation,
            invalidFormInputNames: value,
          },
        });
        break;
      }

      case 'delete-invalid-form-input-name': {
        const invalidFormInputNames = {
          ...doc,
        }.validation.invalidFormInputNames.filter((name) => name !== value);
        sharedClientDocumentVariableWithLocalStorage({
          ...doc,
          validation: {
            ...doc.validation,
            invalidFormInputNames,
          },
        });
        break;
      }
    }
  };

  const fileAttachmentActions: FileAttachmentActions = ({ type, value }) => {
    const doc = sharedClientDocumentVariable();
    switch (type) {
      case 'add-profile-picture': {
        const { url, ...rest } = value;
        const fileAttachments: VitaDocumentFileAttachmentsFragment = {
          ...doc.fileAttachments,
          profileImage: { url },
        };

        sharedClientDocumentVariableWithLocalStorage({
          ...doc,
          fileAttachments,
          profileImage: { ...rest, delete: false },
        });
        break;
      }

      case 'delete-profile-picture': {
        const profileImage = {
          id: doc.fileAttachments?.profileImage?.id,
          delete: true,
        };

        sharedClientDocumentVariableWithLocalStorage({
          ...doc,
          fileAttachments: null,
          profileImage,
        });
      }
    }
  };

  const toggleShowAnschreibenBanner = (): void => {
    const localDocument =
      global?.window?.localStorage?.getItem('vita-document');
    const doc = localDocument
      ? JSON.parse(localDocument)
      : sharedClientDocumentVariable();

    sharedClientDocumentVariableWithLocalStorage({
      ...doc,
      showAnschreibenBanner: !doc.showAnschreibenBanner,
    });
  };

  const deleteLoggedInData = (): void => {
    sharedClientDocumentVariableWithLocalStorage(
      vitaClientDocumentInitialValues
    );
  };

  return {
    themeActions,
    cvActions,
    documentActions,
    validationActions,
    fileAttachmentActions,
    toggleShowAnschreibenBanner,
    deleteLoggedInData,
  };
};
