import type { VitaCareerLevelFieldFragment } from '@xing-com/crate-lebenslauf-graphql';

export const careerLevels: VitaCareerLevelFieldFragment[] = [
  { id: '1', label: 'Student·in / Praktikant·in' },
  { id: '2', label: 'Berufseinsteiger·in' },
  { id: '3', label: 'Mit Berufserfahrung' },
  { id: '4', label: 'Manager (mit und ohne Personalverantwortung)' },
  { id: '5', label: 'Direktor·in (Bereichsleitung, VP, SVP etc.)' },
  { id: '6', label: 'Geschäftsführer·in (GF, CEO etc.)' },
];
