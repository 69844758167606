import type React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  useVitaTracking,
  useVitaHost,
  useVitaReactiveVariableActions,
} from '@xing-com/crate-lebenslauf-hooks';
import { useViewerData } from '@xing-com/hub';
import { usePopOver } from '@xing-com/pop-over';
import { ProfileImage } from '@xing-com/profile-image';

import {
  StyledLoginButton,
  StyledProfileMenu,
  StyledMenu,
  StyledLink,
  StyledSkeletonButton,
} from './login.styled';

type LoginProps = {
  isOnProgressBar?: boolean;
};

export const Login: React.FC<LoginProps> = ({ isOnProgressBar }) => {
  const { data, loading } = useViewerData();
  const { loginUrl, logoutUrl } = useVitaHost();
  const { trackInteraction } = useVitaTracking();
  const isLoggedIn = data?.loginState === 'LOGGED_IN';
  const popOver = usePopOver();
  const { deleteLoggedInData } = useVitaReactiveVariableActions();

  if (loading) {
    return <StyledSkeletonButton size="small" />;
  }

  return (
    <>
      {isLoggedIn ? (
        <>
          <StyledProfileMenu
            onClick={popOver.togglePopOver}
            data-qa="profile-menu"
          >
            <ProfileImage
              profileName={data.user?.displayName}
              size="xsmall"
              src={data.user?.profileImage[0].url}
            />
          </StyledProfileMenu>
          <StyledMenu
            $isOnProgressBar={isOnProgressBar}
            dimmerTitle="Close menu"
            onOutsideClick={popOver.handleHide}
            show={popOver.show}
            triggerRef={popOver.triggerRef}
          >
            <StyledLink
              href="https://www.xing.com/profile"
              target="_blank"
              rel="noreferrer"
            >
              <FormattedMessage id="CRATE_LEBENSLAUF_LOGIN_PROFILE" />
            </StyledLink>
            <StyledLink
              href="https://faq.xing.com"
              target="_blank"
              rel="noreferrer"
            >
              <FormattedMessage id="CRATE_LEBENSLAUF_LOGIN_HELP" />
            </StyledLink>
            <StyledLink
              href={logoutUrl}
              onClick={async () => {
                trackInteraction('lebenslauf_editor_logout_click', {
                  event: 'EventLogout',
                  EventLogout: 1,
                });
                deleteLoggedInData();
                await new Promise((resolve) => setTimeout(resolve, 100));
                if (window) window.location.reload();
              }}
              target="_blank"
              rel="noreferrer"
            >
              <FormattedMessage id="CRATE_LEBENSLAUF_LOGIN_LOGOUT" />
            </StyledLink>
          </StyledMenu>
        </>
      ) : (
        <StyledLoginButton
          variant="primary"
          data-qa="login-button"
          onClick={() => trackInteraction('lebenslauf_editor_login_click')}
          href={loginUrl}
          size="small"
        >
          <FormattedMessage id="CRATE_LEBENSLAUF_LOGIN_BUTTON" />
        </StyledLoginButton>
      )}
    </>
  );
};
