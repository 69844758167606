/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconTopicChannels = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconTopicChannels"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.067 15.394 22 16.566l-10 6.066-10-6.066 1.933-1.172L12 20.286zm0-4.5L22 12.066l-10 6.066-10-6.066 1.933-1.172L12 15.786zM12 1.5l10 6.065v.002l-10 6.065L2 7.566zm0 2.345L5.867 7.566 12 11.286l6.134-3.72z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconTopicChannels;
