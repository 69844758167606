import type { VitaIndustryFieldFragment } from '@xing-com/crate-lebenslauf-graphql';

export const industries: VitaIndustryFieldFragment[] = [
  {
    id: '60400',
    label: 'Abfälle, Recycling',
  },
  {
    id: '210400',
    label: 'Alternative Medizin',
  },
  {
    id: '10400',
    label: 'Architektur',
  },
  {
    id: '10000',
    label: 'Architektur und Bauwesen',
  },
  {
    id: '210300',
    label: 'Arztpraxen',
  },
  {
    id: '30000',
    label: 'Automobil- und Fahrzeugbau',
  },
  {
    id: '30100',
    label: 'Automobile und Zweiräder',
  },
  {
    id: '70200',
    label: 'Bahnverkehr',
  },
  {
    id: '120000',
    label: 'Banken, Finanzdienstleistungen',
  },
  {
    id: '120200',
    label: 'Bankwesenv',
  },
  {
    id: '10200',
    label: 'Baugewerbe',
  },
  {
    id: '10100',
    label: 'Bauingenieurwesen',
  },
  {
    id: '10300',
    label: 'Baustoffe',
  },
  {
    id: '160000',
    label: 'Beratung, Consulting',
  },
  {
    id: '230600',
    label: 'Bergbau, Metalle',
  },
  {
    id: '220600',
    label: 'Bibliotheken',
  },
  {
    id: '40800',
    label: 'Bio- und Nanotechnologie',
  },
  {
    id: '40500',
    label: 'Chemie',
  },
  {
    id: '200500',
    label: 'Coaching, Fortbildung',
  },
  {
    id: '90500',
    label: 'Computer-Hardware',
  },
  {
    id: '90100',
    label: 'Computer-Software',
  },
  {
    id: '90400',
    label: 'Computernetzwerke',
  },
  {
    id: '90800',
    label: 'Computerspiele',
  },
  {
    id: '220100',
    label: 'Darstellende Kunst',
  },
  {
    id: '170300',
    label: 'Design, Grafik',
  },
  {
    id: '40300',
    label: 'Druckwesen',
  },
  {
    id: '200600',
    label: 'E-Learning',
  },
  {
    id: '21200',
    label: 'Einzelhandel',
  },
  {
    id: '41200',
    label: 'Elektrotechnik',
  },
  {
    id: '60000',
    label: 'Energie, Wasser, Umwelt',
  },
  {
    id: '60200',
    label: 'Energiewirtschaft',
  },
  {
    id: '60100',
    label: 'Erneuerbare Energien',
  },
  {
    id: '200000',
    label: 'Erziehung, Bildung, Wissenschaft',
  },
  {
    id: '200300',
    label: 'Fach- und Hochschulen',
  },
  {
    id: '140100',
    label: 'Facility Management',
  },
  {
    id: '30600',
    label: 'Fahrzeugvermietung',
  },
  {
    id: '110400',
    label: 'Film, Musik',
  },
  {
    id: '120100',
    label: 'Finanzdienstleistungen',
  },
  {
    id: '230400',
    label: 'Fischerei',
  },
  {
    id: '220700',
    label: 'Fitness-Studios, Sportvereine und -anlagen',
  },
  {
    id: '200100',
    label: 'Forschung',
  },
  {
    id: '230300',
    label: 'Forstwirtschaft, Jagd',
  },
  {
    id: '220200',
    label: 'Fotografie',
  },
  {
    id: '80400',
    label: 'Freizeiteinrichtungen',
  },
  {
    id: '10500',
    label: 'Garten- und Landschaftsbau',
  },
  {
    id: '80200',
    label: 'Gastronomie',
  },
  {
    id: '190100',
    label: 'Gemeinnützige Einrichtungen und Vereine',
  },
  {
    id: '230700',
    label: 'Geologie',
  },
  {
    id: '210000',
    label: 'Gesundheit, Soziales',
  },
  {
    id: '20200',
    label: 'Getränke',
  },
  {
    id: '20900',
    label: 'Glas, Keramik',
  },
  {
    id: '21100',
    label: 'Großhandel',
  },
  {
    id: '40200',
    label: 'Halbleiter, elektronische Bauteile',
  },
  {
    id: '80100',
    label: 'Hotelgewerbe',
  },
  {
    id: '140000',
    label: 'Immobilien',
  },
  {
    id: '140300',
    label: 'Immobilienvermittlung',
  },
  {
    id: '140200',
    label: 'Immobilienverwaltung',
  },
  {
    id: '21000',
    label: 'Import, Export',
  },
  {
    id: '40000',
    label: 'Industrie und Maschinenbau',
  },
  {
    id: '110100',
    label: 'Informationsdienste',
  },
  {
    id: '190600',
    label: 'Internationale Angelegenheiten',
  },
  {
    id: '90000',
    label: 'Internet, IT',
  },
  {
    id: '90700',
    label: 'Internet, Onlinemedien',
  },
  {
    id: '120300',
    label: 'Investmentbanken',
  },
  {
    id: '90200',
    label: 'IT-Dienstleister',
  },
  {
    id: '90300',
    label: 'IT-Sicherheit',
  },
  {
    id: '110500',
    label: 'Journalismus',
  },
  {
    id: '200400',
    label: 'Kinderbetreuung',
  },
  {
    id: '20000',
    label: 'Konsumgüter, Handel',
  },
  {
    id: '20800',
    label: 'Kosmetik, Körperpflege',
  },
  {
    id: '210100',
    label: 'Krankenhäuser',
  },
  {
    id: '220000',
    label: 'Kunst, Kultur, Sport',
  },
  {
    id: '220400',
    label: 'Kunst, Kunsthandwerk',
  },
  {
    id: '40900',
    label: 'Kunststoff- und Gummiwaren',
  },
  {
    id: '70500',
    label: 'Lagerhaltung',
  },
  {
    id: '230200',
    label: 'Landwirtschaft',
  },
  {
    id: '20100',
    label: 'Lebensmittel',
  },
  {
    id: '30500',
    label: 'Luft- und Raumfahrzeugbau',
  },
  {
    id: '70400',
    label: 'Luftverkehr',
  },
  {
    id: '170000',
    label: 'Marketing, PR und Design',
  },
  {
    id: '170100',
    label: 'Marketing, Werbung',
  },
  {
    id: '170200',
    label: 'Markt- und Meinungsforschung',
  },
  {
    id: '41100',
    label: 'Maschinenbau, Betriebstechnik',
  },
  {
    id: '110000',
    label: 'Medien, Verlage',
  },
  {
    id: '210600',
    label: 'Medizinische Dienste',
  },
  {
    id: '50100',
    label: 'Medizintechnik',
  },
  {
    id: '40100',
    label: 'Mess-, Steuer- und Regelungstechnik',
  },
  {
    id: '170400',
    label: 'Messe, Ausstellungen, Kongresse',
  },
  {
    id: '40700',
    label: 'Metallindustrie und -verarbeitung',
  },
  {
    id: '40600',
    label: 'Mineralöl-Verarbeitung',
  },
  {
    id: '20400',
    label: 'Mode, Textilien',
  },
  {
    id: '220500',
    label: 'Museen, Kultureinrichtungen',
  },
  {
    id: '220300',
    label: 'Musik',
  },
  {
    id: '20600',
    label: 'Möbel, Holzwaren',
  },
  {
    id: '190300',
    label: 'Öffentliche Verwaltung',
  },
  {
    id: '190000',
    label: 'Öffentlicher Dienst, Verbände und Einrichtungen',
  },
  {
    id: '41300',
    label: 'Optische und fotografische Geräte',
  },
  {
    id: '180200',
    label: 'Outsourcing, Offshoring',
  },
  {
    id: '20700',
    label: 'Papierwaren',
  },
  {
    id: '180000',
    label: 'Personaldienstleistungen',
  },
  {
    id: '180100',
    label: 'Personaldienstleistungen und -beratung',
  },
  {
    id: '70100',
    label: 'Personenverkehr',
  },
  {
    id: '210700',
    label: 'Pflegeberufe',
  },
  {
    id: '50000',
    label: 'Pharma, Medizintechnik',
  },
  {
    id: '50200',
    label: 'Pharmazeutische Produkte, Arzneimittel',
  },
  {
    id: '190400',
    label: 'Politik, Verbände',
  },
  {
    id: '70600',
    label: 'Post, Spedition',
  },
  {
    id: '170500',
    label: 'PR',
  },
  {
    id: '210500',
    label: 'Psychologie, Psychotherapie',
  },
  {
    id: '150100',
    label: 'Rechtsberatung',
  },
  {
    id: '80300',
    label: 'Reisebüros und -veranstalter',
  },
  {
    id: '190200',
    label: 'Religiöse Einrichtungen',
  },
  {
    id: '120400',
    label: 'Risikokapital, Private Equity',
  },
  {
    id: '110200',
    label: 'Rundfunk, Fernsehen',
  },
  {
    id: '41000',
    label: 'Rüstung',
  },
  {
    id: '30300',
    label: 'Schienenfahrzeugbau',
  },
  {
    id: '30200',
    label: 'Schiffbau',
  },
  {
    id: '70300',
    label: 'Schifffahrt',
  },
  {
    id: '20500',
    label: 'Schmuck, Luxusgüter',
  },
  {
    id: '200200',
    label: 'Schulen, Kindergärten',
  },
  {
    id: '230800',
    label: 'Sicherheit, Ermittlungen',
  },
  {
    id: '230000',
    label: 'Sonstige Branchen',
  },
  {
    id: '230500',
    label: 'Sonstige Dienstleistungen',
  },
  {
    id: '210800',
    label: 'Sozialwesen',
  },
  {
    id: '230100',
    label: 'Spiel-, Wett- und Lotteriewesen',
  },
  {
    id: '220800',
    label: 'Sporttreibende, Veranstalter, Verbände',
  },
  {
    id: '150300',
    label: 'Steuerberatung',
  },
  {
    id: '20300',
    label: 'Tabakwaren',
  },
  {
    id: '100000',
    label: 'Telekommunikation',
  },
  {
    id: '110700',
    label: 'Text, Lektorat',
  },
  {
    id: '210200',
    label: 'Tiermedizin',
  },
  {
    id: '80000',
    label: 'Tourismus, Gastronomie',
  },
  {
    id: '70000',
    label: 'Transport, Logistik',
  },
  {
    id: '110600',
    label: 'Übersetzen, Dolmetschen',
  },
  {
    id: '60500',
    label: 'Umweltschutz',
  },
  {
    id: '90600',
    label: 'Unterhaltungselektronik',
  },
  {
    id: '40400',
    label: 'Verbundwerkstoffe',
  },
  {
    id: '30400',
    label: 'Verkehrstechnik',
  },
  {
    id: '110300',
    label: 'Verlagswesen',
  },
  {
    id: '130000',
    label: 'Versicherungen',
  },
  {
    id: '190500',
    label: 'Verteidigung, Justiz, Polizei',
  },
  {
    id: '60300',
    label: 'Wasserversorgung und -entsorgung',
  },
  {
    id: '150200',
    label: 'Wirtschaftsprüfung',
  },
  {
    id: '150000',
    label: 'Wirtschaftsprüfung, Steuern, Recht',
  },
];
