import { useLocation } from '@reach/router';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button as BreweryButton } from '@xing-com/button';
import { useMatchMedia } from '@xing-com/crate-hooks-use-match-media';
import { Button } from '@xing-com/crate-lebenslauf-button';
import { Login } from '@xing-com/crate-lebenslauf-components';
import {
  useIsDarkMode,
  useBasePath,
  useVitaReactiveVariableActions,
  useReadVitaReactiveVariable,
  useIsCrateApp,
  useIsCoverLetterEditor,
  useIsCoverLetterRoot,
} from '@xing-com/crate-lebenslauf-hooks';
import type { FrameProps } from '@xing-com/crate-lebenslauf-xinglets';
import { useHost } from '@xing-com/crate-xinglet';
import { IconHamburgerMenu, IconCross, IconArrowRight } from '@xing-com/icons';
import { mediaWideNavless } from '@xing-com/layout-tokens';

import AnschreibenLogoDark from './assets/anschreiben-logo-dark.svg';
import AnschreibenLogoLight from './assets/anschreiben-logo-light.svg';
import appAppleTouchIcon from './assets/apple-touch-icon-precomposed.png';
import appFavicon from './assets/favicon.ico';
import LebenslaufLogoDark from './assets/lebenslauf-logo-dark.svg';
import LebenslaufLogoLight from './assets/lebenslauf-logo-light.svg';
import { Footer } from './footer';
import {
  GlobalStyles,
  StyledHeader,
  StyledMainContent,
  StyledExpandedNavigation,
  StyledCollapsedNavigation,
  StyledLogo,
  StyledLogoLink,
  StyledIconButton,
  StyledMenuWrapper,
  StyledLink,
  StyledIconNewChat,
  StyledIconNews,
  StyledIconTopicChannels,
} from './frame.styled';

const useSelectedNavItem = (): string => {
  const { pathname } = useLocation();

  if (pathname === `/ratgeber/lebenslauf-aufbau-inhalt`) {
    return 'topicChannels';
  }

  if (pathname === `/ratgeber`) {
    return 'news';
  }

  if (pathname === `/vorlagen-und-muster`) {
    return 'newChat';
  }

  return '';
};

export const Frame: React.FC<FrameProps> = ({
  children,
  hideFooter,
  noMainContentWrapperStyles = false,
  isEditorPage = false,
  className,
}) => {
  const { formatMessage } = useIntl();
  const basePath = useBasePath();
  const { pathname } = useLocation();
  const isCoverLetterEditor = useIsCoverLetterEditor();
  const isCoverLetterRoot = useIsCoverLetterRoot();
  const { getHostname } = useHost();
  const isMobile = !useMatchMedia(mediaWideNavless); // be careful: during SSR it will be always true
  const isDarkMode = useIsDarkMode();
  const isCrateApp = useIsCrateApp();
  const { showAnschreibenBanner } = useReadVitaReactiveVariable();
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const { toggleShowAnschreibenBanner } = useVitaReactiveVariableActions();
  const selectedNavItem = useSelectedNavItem();

  React.useEffect(() => {
    if (!isCrateApp) toggleShowAnschreibenBanner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCrateApp]);

  const StyledNavigation =
    isMenuExpanded && isMobile
      ? StyledExpandedNavigation
      : StyledCollapsedNavigation;

  return (
    <>
      <GlobalStyles />
      <Helmet>
        <link rel="shortcut icon" href={appFavicon} type="image/x-icon" />
        <link rel="apple-touch-icon-precomposed" href={appAppleTouchIcon} />
        <link
          rel="canonical"
          href={`https://${getHostname()}${pathname}`.replace(/\/$/, '')}
        />
      </Helmet>

      <StyledHeader>
        <StyledNavigation>
          <StyledLogoLink
            to={`${basePath}/`}
            aria-label={formatMessage({
              id: 'CRATE_LEBENSLAUF_NAVIGATION_LOGO',
            })}
          >
            <StyledLogo
              src={
                isCoverLetterRoot
                  ? isDarkMode
                    ? AnschreibenLogoDark
                    : AnschreibenLogoLight
                  : isDarkMode
                    ? LebenslaufLogoDark
                    : LebenslaufLogoLight
              }
              alt={formatMessage({
                id: 'CRATE_LEBENSLAUF_NAVIGATION_LOGO',
              })}
            />
          </StyledLogoLink>
          {isMobile && (
            <StyledIconButton
              icon={isMenuExpanded ? IconCross : IconHamburgerMenu}
              size="large"
              onClick={() => {
                setIsMenuExpanded(!isMenuExpanded);
              }}
              aria-label={
                isMenuExpanded && isMobile
                  ? formatMessage({
                      id: 'CRATE_LEBENSLAUF_NAVIGATION_CLOSE_MENU',
                    })
                  : formatMessage({
                      id: 'CRATE_LEBENSLAUF_NAVIGATION_OPEN_MENU',
                    })
              }
              // @ts-expect-error FIXME: SC6
              $expanded={isMenuExpanded && isMobile}
            />
          )}
          {(isMobile || (!isMobile && !isEditorPage)) && (
            <StyledMenuWrapper
              $expanded={isMenuExpanded && isMobile}
              $hideOnDesktop={isEditorPage}
            >
              {!isCoverLetterRoot && (
                <>
                  <StyledLink
                    to={`${basePath}/ratgeber/lebenslauf-aufbau-inhalt`}
                    $opened={selectedNavItem === 'topicChannels'}
                    $expanded={isMenuExpanded && isMobile}
                  >
                    <StyledIconTopicChannels width={24} height={24} />
                    <FormattedMessage id="CRATE_LEBENSLAUF_NAVIGATION_STRUCTURE" />
                  </StyledLink>
                  <StyledLink
                    to={`${basePath}/vorlagen-und-muster`}
                    $opened={selectedNavItem === 'newChat'}
                    $expanded={isMenuExpanded && isMobile}
                  >
                    <StyledIconNewChat width={24} height={24} />
                    <FormattedMessage id="CRATE_LEBENSLAUF_NAVIGATION_TEMPLATES" />
                  </StyledLink>
                </>
              )}

              <StyledLink
                to={`${basePath}/ratgeber`}
                $opened={selectedNavItem === 'news'}
                $expanded={isMenuExpanded && isMobile}
              >
                <StyledIconNews width={24} height={24} />
                <FormattedMessage id="CRATE_LEBENSLAUF_NAVIGATION_ADVISORY" />
              </StyledLink>

              {!isEditorPage && !isCoverLetterRoot ? (
                <Button
                  variant="primary"
                  size="small"
                  data-qa="lebenslauf-header-cta"
                  to={`${basePath}/neu?sc_o=lebenslauf_home_create_cv_click&sc_o_PropActionOrigin=lebenslauf_home_top_navigation`}
                >
                  <FormattedMessage id="CRATE_LEBENSLAUF_NAVIGATION_CREATE_CV" />
                </Button>
              ) : null}
              {!isEditorPage && isCoverLetterRoot ? (
                <Button
                  variant="primary"
                  size="small"
                  data-qa="anschreiben-header-cta"
                  to={`${basePath}/anschreiben`} // TODO: add tracking params
                >
                  <FormattedMessage id="CRATE_LEBENSLAUF_NAVIGATION_CREATE_COVER_LETTER" />
                </Button>
              ) : null}
              {isCoverLetterEditor && isEditorPage && (
                <BreweryButton
                  variant="proJobs"
                  to={`${basePath}/neu`}
                  size="small"
                  icon={IconArrowRight}
                >
                  <FormattedMessage id="CRATE_VITA_TEMPLATE_TAG" />
                </BreweryButton>
              )}
              <Login />
            </StyledMenuWrapper>
          )}
        </StyledNavigation>
      </StyledHeader>

      <StyledMainContent
        $noMainContentWrapperStyles={noMainContentWrapperStyles}
        $hasBanner={!!showAnschreibenBanner}
        className={className}
      >
        {children}
      </StyledMainContent>

      {hideFooter ? null : <Footer />}
    </>
  );
};
